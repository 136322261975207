@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.testimony-main-container {
  overflow: hidden;
  margin-inline: -20px;
  background: #fff; }

@media screen and (min-width: 1008px) {
  .testimony-main-container {
    overflow: hidden;
    margin-inline: -220px; } }

.testimony-titles-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 2rem 0 0;
  gap: 1.5rem; }
  .testimony-titles-container .testimony-title {
    color: #8a8a8a;
    text-align: center;
    font-size: .75rem;
    font-family: "Raleway", serif;
    font-weight: 500;
    margin: unset;
    text-transform: uppercase; }
  .testimony-titles-container .testimony-subtitle {
    color: #353a42;
    text-align: center;
    font-size: 2rem;
    font-family: "Raleway", serif;
    font-weight: 300;
    margin: unset;
    margin-bottom: 1.5rem; }
    @media screen and (min-width: 1008px) {
      .testimony-titles-container .testimony-subtitle {
        font-size: 3rem;
        margin-bottom: 3rem; } }

.slick-slider {
  position: relative; }
  .slick-slider .slick-prev,
  .slick-slider .slick-next {
    background: none;
    border: none;
    opacity: 0.75;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    position: absolute;
    top: 38%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer; }
  .slick-slider button.slick-prev {
    left: 27.9%;
    /* Altere para ajustar a posição */ }
  .slick-slider button.slick-next {
    right: 31.1%;
    /* Altere para ajustar a posição */ }
  .slick-slider .slick-prev::before {
    content: url("../../../assets/svg/left-arrow.svg");
    display: block; }
  .slick-slider .slick-next::before {
    content: url("../../../assets/svg/right-arrow.svg");
    display: block; }

.testimonial-slide .testimonial-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  gap: 1rem;
  margin-bottom: 2rem;
  height: 345px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  @media screen and (min-width: 1008px) and (max-width: 1200px) {
    .testimonial-slide .testimonial-content {
      height: 420px; } }
  @media screen and (min-width: 1201px) {
    .testimonial-slide .testimonial-content {
      padding: 48px 52px;
      margin-bottom: 80px;
      height: 355px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; } }
  .testimonial-slide .testimonial-content .testimonial-profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: .75rem; }
    .testimonial-slide .testimonial-content .testimonial-profile .testimonial-name-position {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 6px; }
      .testimonial-slide .testimonial-content .testimonial-profile .testimonial-name-position .testimonial-name {
        color: #353a42;
        font-family: "Raleway", serif;
        font-size: 1rem;
        margin: unset;
        font-weight: 500; }
      .testimonial-slide .testimonial-content .testimonial-profile .testimonial-name-position .testimonial-position {
        color: #353a42;
        font-family: "Raleway", serif;
        font-size: .875rem;
        margin: unset;
        font-weight: 300; }
    .testimonial-slide .testimonial-content .testimonial-profile .testimonial-image {
      max-width: 60px; }
  .testimonial-slide .testimonial-content .testimonial-desciption .testimonial-description-content {
    color: #353a42;
    font-family: "Raleway", serif;
    font-size: 12px;
    font-style: italic;
    font-weight: 300;
    line-height: 1.5rem;
    margin: unset; }
    @media screen and (min-width: 1008px) {
      .testimonial-slide .testimonial-content .testimonial-desciption .testimonial-description-content {
        font-size: 1rem;
        line-height: 2rem; } }
    .testimonial-slide .testimonial-content .testimonial-desciption .testimonial-description-content span {
      font-weight: 600; }
  .testimonial-slide .testimonial-content .testimonial-video .testimonial-button-video {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #353a42;
    cursor: pointer;
    font-size: .75rem;
    background: #0d98e6;
    width: 180px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 12px 40px;
    border-radius: 8px;
    border: none;
    color: #fff;
    gap: 10px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap; }
  @media screen and (min-width: 1008px) {
    .testimonial-slide .testimonial-content .testimonial-video .testimonial-button-video {
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      cursor: pointer;
      font-size: .75rem;
      background: #0d98e6;
      width: 180px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 12px 40px;
      border-radius: 8px;
      border: none;
      color: #fff;
      gap: 20px;
      text-decoration: none;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-transition: ease-in-out 0.6s;
      transition: ease-in-out 0.6s;
      -webkit-animation: ease-in-out 0.6s;
      animation: ease-in-out 0.6s;
      white-space: nowrap; }
      .testimonial-slide .testimonial-content .testimonial-video .testimonial-button-video:hover {
        background: #053766;
        width: 200px; }
        .testimonial-slide .testimonial-content .testimonial-video .testimonial-button-video:hover .testiomonial-video-arrow {
          display: block;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          -webkit-transition: fadeIn ease-in-out 0.6s;
          transition: fadeIn ease-in-out 0.6s;
          -webkit-animation: fadeIn ease-in-out 0.6s;
          animation: fadeIn ease-in-out 0.6s; }
      .testimonial-slide .testimonial-content .testimonial-video .testimonial-button-video .testiomonial-video-arrow {
        display: none; }
    @-webkit-keyframes fadeIn {
      from {
        opacity: 0; }
      to {
        opacity: 1; } }
    @keyframes fadeIn {
      from {
        opacity: 0; }
      to {
        opacity: 1; } } }

.slick-track {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.slick-slide {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease; }

.slick-center .testimonial-content {
  -webkit-transform: scale(1);
  transform: scale(1); }

.slick-slide:not(.slick-center) {
  opacity: 1;
  -webkit-filter: blur(3px);
  filter: blur(3px);
  -webkit-transform: scale(0.9);
  transform: scale(0.9); }
  @media screen and (min-width: 1008px) {
    .slick-slide:not(.slick-center) {
      opacity: 1;
      -webkit-filter: blur(3px);
      filter: blur(3px);
      -webkit-transform: scale(0.75);
      transform: scale(0.75); } }

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  /* Fundo escuro */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer; }

.modal-content {
  position: relative;
  border-radius: 8px;
  max-width: 800px;
  z-index: 1001;
  width: 100%;
  border-radius: 8px; }
  .modal-content .modal-content-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1001;
    aspect-ratio: 9 / 16;
    max-height: 100%; }
    @media screen and (min-width: 1008px) {
      .modal-content .modal-content-inner {
        aspect-ratio: 16 / 9; } }
    .modal-content .modal-content-inner .modal-close-button {
      top: 140px;
      position: absolute;
      right: 78px;
      border-radius: 4px;
      border: none;
      background: none;
      z-index: 999; }
    @media screen and (min-width: 1008px) {
      .modal-content .modal-content-inner .modal-close-button {
        top: 4px;
        position: absolute;
        right: 4px;
        border-radius: 4px;
        border: none;
        background: none;
        z-index: 999; }
        .modal-content .modal-content-inner .modal-close-button img:hover {
          -webkit-filter: opacity(0.8);
          filter: opacity(0.8);
          cursor: pointer; } }
    .modal-content .modal-content-inner .modal-navigation {
      position: relative;
      width: 65%;
      border-radius: 10px;
      aspect-ratio: 9 / 16; }
      @media screen and (min-width: 1008px) {
        .modal-content .modal-content-inner .modal-navigation {
          width: 100%;
          aspect-ratio: 16 / 9; } }
    .modal-content .modal-content-inner .modal-arrow-left {
      position: absolute;
      top: 45%;
      left: -70px;
      cursor: pointer;
      background: none;
      border: none; }
    .modal-content .modal-content-inner .modal-arrow-right {
      position: absolute;
      top: 45%;
      right: -70px;
      cursor: pointer;
      background: none;
      border: none; }
