@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.home-container-desk {
  margin-inline: 250px; }
  @media (min-width: 1600px) {
    .home-container-desk {
      margin-inline: 350px; } }
  .home-container-desk .block-title {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 500;
    color: #353a42;
    font-size: 2.5rem;
    line-height: 1.1; }
    @media (min-width: 1800px) {
      .home-container-desk .block-title {
        font-size: 3rem; } }
  .home-container-desk .first-fold-text {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #353a42;
    text-align: center;
    margin-right: 15%; }
  .home-container-desk .text-highlight {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #353a42;
    color: #0d98e6;
    font-weight: 500; }
  .home-container-desk .tag {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #353a42;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    border: none;
    color: #8a8a8a;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-inline: 20px; }
  .home-container-desk .home-paragraph {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #353a42;
    font-size: 0.875rem; }
    .home-container-desk .home-paragraph i {
      font-size: 1.25rem;
      font-weight: 300; }
  .home-container-desk .light-background-quote {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #353a42;
    font-style: italic;
    background-color: #0d98e6;
    padding: 20px;
    font-size: 1.5rem;
    font-weight: 300;
    color: #fff;
    margin-bottom: 0; }
    .home-container-desk .light-background-quote strong {
      font-weight: 500;
      text-decoration: underline; }
  .home-container-desk .know-more-link {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #353a42;
    cursor: pointer;
    font-size: 1rem;
    background: #0d98e6;
    margin-inline: auto;
    width: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 12px 40px;
    border-radius: 8px;
    color: #fff;
    gap: 20px;
    text-decoration: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: ease-in-out 0.5s;
    transition: ease-in-out 0.5s;
    -webkit-animation: ease-in-out 0.5s;
    animation: ease-in-out 0.5s;
    white-space: nowrap; }
    .home-container-desk .know-more-link:hover {
      background: #053766;
      width: 110px; }
      .home-container-desk .know-more-link:hover svg {
        display: block; }
    .home-container-desk .know-more-link svg {
      display: none; }
  .home-container-desk .first-fold {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 20vh; }
    .home-container-desk .first-fold .block-title {
      text-align: left;
      margin-bottom: 15px; }
    .home-container-desk .first-fold .first-fold-text {
      font-size: 1.125rem;
      text-align: left;
      margin-right: 15%;
      margin-block: 0; }
    .home-container-desk .first-fold .first-fold-text-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: distribute;
      justify-content: space-around; }
      .home-container-desk .first-fold .first-fold-text-container .block-title {
        margin-top: 0; }
    .home-container-desk .first-fold .first-fold-img {
      width: 100%;
      height: auto; }
      @media screen and (min-width: 1500px) {
        .home-container-desk .first-fold .first-fold-img {
          width: 515px; } }
  .home-container-desk .our-clients {
    margin-top: 100px;
    padding: 20px 0 40px;
    background: #f5f5f7;
    max-width: unset !important;
    margin-inline: -20px; }
    @media (min-width: 1008px) {
      .home-container-desk .our-clients {
        max-width: unset !important;
        margin-inline: -250px; } }
  @media (min-width: 1008px) and (min-width: 1600px) {
    .home-container-desk .our-clients {
      margin-inline: -350px; } }
    .home-container-desk .our-clients-container {
      margin-inline: 250px; }
      @media (min-width: 1600px) {
        .home-container-desk .our-clients-container {
          margin-inline: 350px; } }
    .home-container-desk .our-clients .block-title {
      margin-block: 0 16px;
      text-align: center; }
    .home-container-desk .our-clients .tag {
      text-align: center;
      width: auto;
      margin-bottom: 0;
      padding-bottom: 0; }
    .home-container-desk .our-clients .know-more-link {
      margin-top: 60px; }
  .home-container-desk .client-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    row-gap: 8px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
    .home-container-desk .client-list .block-title {
      margin-block: 1.5rem 1rem; }
    .home-container-desk .client-list .client-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      margin-top: 30px; }
      .home-container-desk .client-list .client-wrapper div {
        background-color: #f7f7f7;
        border: none;
        width: 100%;
        height: 82px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; }
        .home-container-desk .client-list .client-wrapper div img {
          width: 123px;
          height: auto; }
        .home-container-desk .client-list .client-wrapper div .kiss {
          height: 35px; }
        .home-container-desk .client-list .client-wrapper div svg {
          width: 123px;
          height: 35px; }
        .home-container-desk .client-list .client-wrapper div:first-child {
          border-radius: 5px 0 0 5px; }
        .home-container-desk .client-list .client-wrapper div:last-child {
          border-radius: 0 5px 5px 0; }
  .home-container-desk .about-us {
    background: #f5f5f7;
    max-width: unset !important;
    margin-inline: -20px;
    position: relative;
    padding-block: 80px; }
    @media (min-width: 1008px) {
      .home-container-desk .about-us {
        max-width: unset !important;
        margin-inline: -250px; } }
  @media (min-width: 1008px) and (min-width: 1600px) {
    .home-container-desk .about-us {
      margin-inline: -350px; } }
    .home-container-desk .about-us-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      background: #fff;
      border: none;
      border-radius: 10px;
      -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
      position: relative;
      margin-inline: 250px;
      padding: 40px; }
      @media (min-width: 1600px) {
        .home-container-desk .about-us-container {
          margin-inline: 350px; } }
    .home-container-desk .about-us .about-us-text-container {
      width: 50%; }
    .home-container-desk .about-us .block-title {
      margin-block: 1.5rem 1rem;
      font-size: 3.5rem; }
    .home-container-desk .about-us .home-paragraph {
      margin-block: 0 2rem;
      font-size: 1.125rem; }
      .home-container-desk .about-us .home-paragraph i {
        font-size: 1.25rem; }
    .home-container-desk .about-us .conecting-img {
      position: absolute;
      max-width: 600px;
      height: auto;
      right: -180px; }
      @media screen and (min-width: 1500px) {
        .home-container-desk .about-us .conecting-img {
          right: -150px; } }
      @media screen and (min-width: 1600px) {
        .home-container-desk .about-us .conecting-img {
          right: -330px;
          max-width: 750px; } }
      @media screen and (min-width: 1700px) {
        .home-container-desk .about-us .conecting-img {
          right: -290px; } }
      @media screen and (min-width: 1900px) {
        .home-container-desk .about-us .conecting-img {
          right: -170px;
          max-width: 750px; } }
  .home-container-desk .about-us-text-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
  .home-container-desk .how-generate-value {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: #fff;
    gap: 50px;
    max-width: unset !important;
    margin-inline: -20px; }
    @media (min-width: 1008px) {
      .home-container-desk .how-generate-value {
        max-width: unset !important;
        margin-inline: -250px; } }
  @media (min-width: 1008px) and (min-width: 1600px) {
    .home-container-desk .how-generate-value {
      margin-inline: -350px; } }
    .home-container-desk .how-generate-value .light-background-quote {
      padding: 120px 60px;
      margin: 0;
      font-size: 2rem; }
      @media (min-width: 1500px) {
        .home-container-desk .how-generate-value .light-background-quote {
          font-size: 2.5rem; } }
    .home-container-desk .how-generate-value .tag {
      margin-top: 20px;
      margin-left: 0;
      padding: 0; }
    .home-container-desk .how-generate-value .block-title {
      margin-block: 10px 15px; }
    .home-container-desk .how-generate-value .how-generate-value-container-text-first {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 50%;
      background: #0d98e6; }
    .home-container-desk .how-generate-value .how-generate-value-text-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding-block: 70px;
      margin-right: 100px; }
      @media (min-width: 1600px) {
        .home-container-desk .how-generate-value .how-generate-value-text-container {
          margin-right: 200px; } }
  .home-container-desk .testimony {
    margin-bottom: 32px; }
    .home-container-desk .testimony .tag {
      text-align: center;
      margin: auto; }
    .home-container-desk .testimony .block-title {
      text-align: center; }
  .home-container-desk .testimony-slider .slick-list {
    padding-inline: 20px;
    overflow-x: hidden;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .home-container-desk .testimony-slider .slick-track {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px; }
  .home-container-desk .testimony-slider .slick-slide {
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .home-container-desk .testimony-slider .slick-slide > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      min-width: 0;
      width: 100%;
      padding-block: 10px;
      height: 236px; }
  .home-container-desk .testimony-slider .testimony-card {
    height: 100%;
    border-radius: 10px;
    padding: 24px 10px;
    background-color: #f7f7f7;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
    .home-container-desk .testimony-slider .testimony-card-content {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: left;
      -ms-flex-align: left;
      align-items: left; }
    .home-container-desk .testimony-slider .testimony-card-introduction {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 12px; }
      .home-container-desk .testimony-slider .testimony-card-introduction-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: start; }
    .home-container-desk .testimony-slider .testimony-card-name {
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      font-weight: 400;
      font-size: 1rem;
      margin-block: 2px; }
    .home-container-desk .testimony-slider .testimony-card-post {
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      font-weight: 100;
      margin-block: 2px;
      font-size: 0.875rem; }
    .home-container-desk .testimony-slider .testimony-card-text {
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      font-size: 0.875rem;
      margin-block: 16px; }
  .home-container-desk .card-contrast-background {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
  .home-container-desk .our-services {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 32px;
    margin-block: 2rem 0; }
    .home-container-desk .our-services-card {
      -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px; }
      .home-container-desk .our-services-card-son {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background: #f5f5f7;
        margin: 20px;
        gap: 1rem;
        border-radius: 10px;
        padding: 32px 20px; }
        .home-container-desk .our-services-card-son-title {
          font-family: "Raleway", serif;
          font-style: normal;
          font-weight: 300;
          line-height: 1.5;
          color: #353a42;
          margin: 0;
          text-align: center;
          font-size: 1rem;
          font-weight: 500;
          color: #8a8a8a; }
        .home-container-desk .our-services-card-son-text {
          font-family: "Raleway", serif;
          font-style: normal;
          font-weight: 300;
          line-height: 1.5;
          color: #353a42;
          margin: 0;
          text-align: center;
          font-size: 0.875rem;
          font-weight: 300; }
  .home-container-desk .methodology {
    background: #fff;
    padding-top: 40px;
    padding-bottom: 20px; }
    .home-container-desk .methodology .tag {
      margin: 0 20px 4px;
      padding-top: 32px;
      margin: auto; }
    .home-container-desk .methodology .block-title {
      margin-block: 0;
      margin-inline: 20px;
      text-align: center; }
    .home-container-desk .methodology .tag-2 {
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      font-weight: 400;
      border: none;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: 13px 20px;
      font-size: 0.875rem; }
    .home-container-desk .methodology .know-more-link {
      margin-block: 40px; }
    .home-container-desk .methodology .methodology-card-cotainer {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 32px 40px; }
    .home-container-desk .methodology-card-first {
      grid-row: 1;
      grid-column: span 2;
      padding-left: 56px;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      padding-block: 0;
      max-height: 230px; }
      @media screen and (max-width: 1350px) {
        .home-container-desk .methodology-card-first .metodology-container-text {
          margin-block: 10px; } }
      .home-container-desk .methodology-card-first .methodology-card-title {
        text-align: left; }
      .home-container-desk .methodology-card-first .methodology-card-text {
        text-align: left;
        width: 100%; }
      .home-container-desk .methodology-card-first img {
        -webkit-transition: padding 0.5s ease-in-out;
        transition: padding 0.5s ease-in-out; }
        @media screen and (min-width: 1800px) {
          .home-container-desk .methodology-card-first img {
            padding-inline: 180px 40px; } }
    .home-container-desk .methodology-card-first:hover {
      padding-left: 0;
      scale: 1.1; }
      .home-container-desk .methodology-card-first:hover svg {
        margin: 0 !important;
        width: 30% !important; }
        @media screen and (min-width: 1700px) {
          .home-container-desk .methodology-card-first:hover svg {
            width: 20% !important; } }
        @media screen and (min-width: 1800px) {
          .home-container-desk .methodology-card-first:hover svg {
            width: 40% !important; } }
        @media screen and (min-width: 1900px) {
          .home-container-desk .methodology-card-first:hover svg {
            width: 20% !important; } }
      @media screen and (min-width: 1800px) {
        .home-container-desk .methodology-card-first:hover img {
          padding-inline: 0px 100px; } }
      @media screen and (min-width: 1900px) {
        .home-container-desk .methodology-card-first:hover img {
          padding-inline: 90px 100px; } }
    .home-container-desk .methodology .methodology-card-second-row {
      padding: 30px 25px;
      max-width: 585px;
      -webkit-transition: all 0.5s ease-in;
      transition: all 0.5s ease-in;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content; }
      .home-container-desk .methodology .methodology-card-second-row:hover {
        scale: 1.1; }
    .home-container-desk .methodology-card {
      border-radius: 10px;
      background: #f5f5f7;
      -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .home-container-desk .methodology-card .know-more-link {
        opacity: 0;
        width: 0;
        height: 0;
        margin-left: 0;
        margin-block: 0;
        padding: 0;
        -webkit-transition: all ease-in-out 0.5s;
        transition: all ease-in-out 0.5s; }
      .home-container-desk .methodology-card svg {
        opacity: 0;
        width: 0;
        -webkit-transition: all ease-in-out 0.5s;
        transition: all ease-in-out 0.5s; }
      .home-container-desk .methodology-card:hover svg {
        opacity: 1;
        width: 80%;
        margin-right: 10px; }
        @media screen and (min-width: 1500px) {
          .home-container-desk .methodology-card:hover svg {
            width: 40%; } }
        @media screen and (min-width: 1900px) {
          .home-container-desk .methodology-card:hover svg {
            width: 25%; } }
      .home-container-desk .methodology-card:hover .know-more-link {
        opacity: 1;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        -webkit-transition: all ease-in-out 0.5s;
        transition: all ease-in-out 0.5s;
        margin-block: 15px 0;
        width: 80px;
        padding: 12px 40px; }
        .home-container-desk .methodology-card:hover .know-more-link .know-more-arrow {
          -webkit-transition: all ease 0.5s;
          transition: all ease 0.5s;
          -webkit-animation: all ease 0.5s;
          animation: all ease 0.5s;
          opacity: 0;
          width: 0; }
      .home-container-desk .methodology-card:hover .know-more-link:hover {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 110px; }
        .home-container-desk .methodology-card:hover .know-more-link:hover .know-more-arrow {
          opacity: 1;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content; }
      .home-container-desk .methodology-card .metodology-container-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; }
      .home-container-desk .methodology-card-title {
        font-family: "Raleway", serif;
        font-style: normal;
        font-weight: 300;
        line-height: 1.5;
        color: #353a42;
        margin-block: 0 0.5rem;
        text-align: left;
        font-size: 1.25rem;
        font-weight: 500; }
      .home-container-desk .methodology-card-text {
        font-family: "Raleway", serif;
        font-style: normal;
        font-weight: 300;
        line-height: 1.5;
        color: #353a42;
        margin: 0;
        text-align: left;
        font-size: 1rem; }
