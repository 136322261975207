@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.footer-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }
  .footer-container-form-bg {
    padding: 2rem 16px 1.25rem;
    position: relative;
    background-color: #0d98e6;
    overflow: hidden; }
    .footer-container-form-bg-circle-1 {
      position: absolute;
      width: 467px;
      height: 477px;
      left: -101px;
      top: -110.77px;
      background: rgba(219, 255, 0, 0.2);
      -webkit-filter: blur(52px);
      filter: blur(52px); }
    .footer-container-form-bg-circle-2 {
      position: absolute;
      width: 330px;
      height: 330px;
      left: 181px;
      top: -32.77px;
      background: rgba(48, 230, 175, 0.2);
      -webkit-filter: blur(90px);
      filter: blur(90px); }
    .footer-container-form-bg-circle-3 {
      position: absolute;
      width: 309px;
      height: 309px;
      left: 157px;
      top: 252.23px;
      background: rgba(161, 48, 230, 0.4);
      -webkit-filter: blur(52px);
      filter: blur(52px); }
    .footer-container-form-bg-circle-4 {
      position: absolute;
      width: 330px;
      height: 330px;
      left: -90px;
      top: 461px;
      background: rgba(48, 230, 175, 0.3);
      -webkit-filter: blur(90px);
      filter: blur(90px); }
  .footer-container-form-container {
    padding: 1.5rem 20px;
    background: rgba(255, 255, 255, 0.85);
    border-radius: 10px;
    backdrop-filter: blur(6.7px);
    -webkit-backdrop-filter: blur(6.7px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .footer-container-form-container .submit-botao {
      all: unset;
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      background-color: #0d98e6;
      color: #fff;
      border: none;
      border-radius: 5px;
      margin-top: 1.25rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      text-align: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-size: 1rem;
      font-weight: 500;
      height: 42px; }
      .footer-container-form-container .submit-botao-disabled {
        background-color: WHITE;
        color: #0d98e6;
        border: 1px #cfcfcf solid; }
  .footer-container .tag {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #353a42;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    border: solid 1px #ececec;
    color: #8a8a8a;
    background-color: #f7f7f7;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    margin: 0; }
  .footer-container .block-title {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #353a42;
    font-weight: 300;
    font-size: 2rem;
    line-height: 1.1;
    margin-block: 1.25rem 0.5rem; }
  .footer-container .text-highlight {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #353a42;
    color: #0d98e6;
    font-weight: 500; }
  .footer-container .home-paragraph {
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #353a42;
    font-size: 0.875rem;
    text-align: center;
    margin-block: 0 1.25rem; }
  .footer-container form {
    width: 100%; }
    .footer-container form label {
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      font-weight: 400;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      font-size: 0.875rem;
      margin-top: 1rem; }
      .footer-container form label:first-child {
        margin: 0; }
    .footer-container form input {
      height: 32px;
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      border: 1px solid white;
      border-radius: 4px;
      background-color: transparent;
      margin-top: 0.625rem;
      padding-inline: 14px;
      color: #8a8a8a;
      font-size: 0.75rem;
      width: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box; }
      .footer-container form input:focus {
        background-color: transparent;
        border: 1px solid #0d98e6;
        outline: none; }
    .footer-container form .enviar {
      all: unset;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
    .footer-container form textarea {
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      height: 119px;
      max-width: 100%;
      min-width: 100%;
      border: 1px solid white;
      border-radius: 4px;
      background-color: transparent;
      margin-top: 0.625rem;
      padding-inline: 14px;
      color: #8a8a8a;
      font-size: 0.75rem;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding-top: 8px; }
    .footer-container form textarea:focus {
      background-color: transparent;
      border: 1px solid #0d98e6;
      outline: none; }
  .footer-container .footer-container-links {
    padding: 20px;
    background-color: #053766;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
    .footer-container .footer-container-links > ul {
      gap: 3rem; }
    .footer-container .footer-container-links ul {
      list-style: none;
      padding: 0;
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      font-size: 1rem;
      font-weight: 500;
      color: #8a8a8a;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; }
    .footer-container .footer-container-links .social-media {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      gap: 20px; }
    .footer-container .footer-container-links li {
      line-height: 1;
      list-style: none; }
      .footer-container .footer-container-links li > ul {
        gap: 0.375rem; }
      .footer-container .footer-container-links li a {
        font-family: "Raleway", serif;
        font-style: normal;
        font-weight: 300;
        line-height: 1.5;
        color: #353a42;
        font-size: 0.875rem;
        font-weight: 300;
        color: #fff;
        text-decoration: none; }
        .footer-container .footer-container-links li a:visited {
          color: #fff; }
      .footer-container .footer-container-links li .link-scroll {
        font-family: "Raleway", serif;
        font-style: normal;
        font-weight: 300;
        line-height: 1.5;
        color: #353a42;
        font-size: 0.875rem;
        font-weight: 300;
        color: #fff;
        margin: 0; }
        .footer-container .footer-container-links li .link-scroll:visited {
          color: #fff; }
  .footer-container .footer-final {
    background: #353a42;
    font-family: "Raleway", serif;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #353a42; }
    .footer-container .footer-final-container {
      margin-inline: 20px;
      padding-top: 10px;
      color: #fff;
      font-size: 0.75rem; }
      .footer-container .footer-final-container img {
        max-width: 110px; }
