@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0; }

.header-container {
  position: fixed;
  top: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  z-index: 5;
  -webkit-transition: top 0.5s ease;
  transition: top 0.5s ease; }
  .header-container .headercontainer-layout {
    padding: 20px 20px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .header-container .headercontainer-layout svg {
      padding-left: 10px; }

.header-container-ativo {
  -webkit-transition: top 0.5s ease;
  transition: top 0.5s ease;
  margin: auto;
  position: fixed;
  top: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 5;
  width: 100%; }
  .header-container-ativo .headercontainer-layout {
    padding: 15px 10px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 90vw;
    margin: auto;
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px; }
    .header-container-ativo .headercontainer-layout svg {
      padding-left: 10px; }

.home-link {
  margin: 0; }
