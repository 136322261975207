@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.botao-menu {
  display: block;
  z-index: 5;
  padding-right: 10px; }

.botao-menu.active {
  padding-right: 10px;
  display: none; }

.menu-container {
  width: 0;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease; }

.menu-container-open {
  inset: 0;
  display: block;
  z-index: 1;
  position: fixed;
  width: 100%;
  height: auto;
  inset: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 20px;
  background: #053766;
  overflow-y: scroll;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .menu-container-open .link-home {
    margin: 0; }
  .menu-container-open .menu-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: unset !important;
    margin-inline: -20px;
    margin-top: -20px; }
    @media (min-width: 1008px) {
      .menu-container-open .menu-header {
        max-width: unset !important;
        margin-inline: -250px; } }
  @media (min-width: 1008px) and (min-width: 1600px) {
    .menu-container-open .menu-header {
      margin-inline: -350px; } }
    .menu-container-open .menu-header svg {
      padding: 30px;
      padding-top: 20px; }
    .menu-container-open .menu-header a {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
  .menu-container-open .menu-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-block: 13% 25%;
    gap: 1.5rem; }
    .menu-container-open .menu-body .institutional-links {
      font-family: "Raleway", serif;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5;
      color: #353a42;
      font-weight: 100;
      font-size: 2rem;
      color: #fff;
      text-decoration: none;
      margin: 0; }
  .menu-container-open .menu-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 2rem; }
    .menu-container-open .menu-footer .menu-address {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; }
      .menu-container-open .menu-footer .menu-address h5 {
        font-family: "Raleway", serif;
        font-style: normal;
        font-weight: 300;
        line-height: 1.5;
        color: #353a42;
        font-size: 0.75rem;
        font-weight: 500;
        color: #8a8a8a;
        margin-block: 0 0.5rem; }
      .menu-container-open .menu-footer .menu-address p {
        font-family: "Raleway", serif;
        font-style: normal;
        font-weight: 300;
        line-height: 1.5;
        color: #353a42;
        font-size: 0.875rem;
        color: #fff;
        margin-block: 0; }
    .menu-container-open .menu-footer .menu-social-media {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; }
      .menu-container-open .menu-footer .menu-social-media .menu-social-media-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 2rem; }
      .menu-container-open .menu-footer .menu-social-media h5 {
        font-family: "Raleway", serif;
        font-style: normal;
        font-weight: 300;
        line-height: 1.5;
        color: #353a42;
        font-size: 0.75rem;
        font-weight: 500;
        color: #8a8a8a;
        margin-block: 0 0.5rem; }
      .menu-container-open .menu-footer .menu-social-media a {
        font-family: "Raleway", serif;
        font-style: normal;
        font-weight: 300;
        line-height: 1.5;
        color: #353a42;
        font-size: 0.875rem;
        color: #fff;
        gap: 0.5rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-block: 0; }
